import './scss/style.scss';
import 'bootstrap';
import { WOW } from 'wowjs';
import { Swiper, Pagination, Navigation } from 'swiper/js/swiper.esm.js';
const fancybox = require('@fancyapps/fancybox');

var wow = new WOW({
    live: false,
    scrollContainer: null
});

$('header').load("header.html", function(response, status, xhr) {
    $('.navbar-collapse').load("block-navbar-collapse-menu.html");
});

$('footer').load("footer.php", function(response, status, xhr) {
    wow.init();
});

$('.block-call-you').load("block-call-you.php", function(response, status, xhr) {
    wow.init();
});

$('#block-banner-internal').load("block-banner-internal.html", function(response, status, xhr) {
    wow.init();
});

$('#block-banner-internal-budget').load("block-banner-internal-budget.html", function(response, status, xhr) {
    wow.init();
});

$('#block-services').load("block-services.html", function(response, status, xhr) {
    wow.init();
});

if ($.contains(document.body, document.getElementById('slider-main'))) {

    Swiper.use([Pagination]);

    var mySwiper = new Swiper('#slider-main', {
        autoplay: {
            delay: 5000,
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
        loop: true
    });
}

if ($.contains(document.body, document.getElementById('slider-internal'))) {

    Swiper.use([Navigation]);

    var mySwiper = new Swiper('#slider-internal', {
        autoplay: {
            delay: 5000,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        loop: true
    });
}

$(window).on("scroll", function(e) {

    if (parseInt(Math.round($(window).scrollTop())) > 0) {
        $('header').addClass('header-scroll');
    } else {
        $('header').removeClass('header-scroll');
    }
});

wow.init();